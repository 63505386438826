export const DEFAULT_VALUES = {
	pageSize: 10,
	order: 'relevant',
	city: 'new-york-city-ny',
} as const

export const EMAIL = {
	support: 'welcome@junehomes.com',
	placeholder: 'email@mail.com',
	privacy: 'privacy@junehomes.com',
	manager: 'stacy@junehomes.com',
	press: 'press@junehomes.com',
	accounting: 'accounting@junehomes.com',
	welcome_extensions: 'welcome+extensions@junehomes.com',
}

export const PHONE_NUMBER = {
	support: '+1 (888) 604-6697',
	maskPlaceholder: `+1\u00A0\u00A0\u007C\u00A0\u00A0(673)\u00A0321-2301`,
}

export const SOCIAL_ACCOUNTS = {
	tiktok: '@june.homes',
}

export const PaymentMethodType = {
	card: 'card',
	ach: 'bank_account',
}

export enum ImageOrientation {
	Landscape = 'landscape',
	Portrait = 'portrait',
}

export enum Hometype {
	Apartment = 'entire_apartment',
	Room = 'private_rooms',
	All = 'all',
}

export enum SeoHometypes {
	Apartment = 'entire_apartment',
	Room = 'private_rooms',
	All = 'all',
	Studio = 'seo_entire_apartments_studio',
	OneBedroom = 'seo_entire_apartments_1br',
	TwoBedroom = 'seo_entire_apartments_2br',
	ThreeBedroom = 'seo_entire_apartments_3br',
	FourthBedroom = 'seo_entire_apartments_4br',
}

export enum ObjectType {
	Room = 1,
	Apartment = 2,
	AsEntire = 3,
}

export enum MoveType {
	Movein = 'movein',
	Moveout = 'moveout',
}

export enum LocalStorageKeys {
	AnalyticsSession = 'analytics_session',
	ReferralBannerVisible = 'referral_banner_visible',
	RoommatesCollapsed = 'roommates_collapsed',
	ToursCount = 'tours_count',
	SignInEmail = 'sign-in-email',
	SignUpEmail = 'sign-up-email',
	SignInPhone = 'sign-in-phone',
	SignUpPhone = 'sign-up-phone',
	SignUpToken = 'sign-up-token',
	SignUpNextUrl = 'sign-up-next-url',
	SignUpSkipTour = 'sign-up-skip-tour',
	MapPoints = 'map-points',
	LastSearchCitySlug = 'last-search-city-slug',
	ExtendPopupHide = 'extend-popup-hide',
	JourneyAdditionalServices = 'journey-additional-services',
	MiFormStepFields = 'mi-form-step-fields',
}

export enum AbExperiment {
	Hotjar = 'hotjar',
	ReviewDetailsSidebar = 'review-details-sidebar',
	ApplyNowButton = 'apply-now-button',
	VirtuallyStagedPhotos = 'virtually-staged-photos',
	NewListingCardLayout = 'new-listing-card-layout',
	SkipReviewDetailsStep = 'skip-review-details-step',
	MapSearchControl = 'map-search-control',
	QuestionOnListingPage = 'question-on-listing-page',
	HideFlexibleMoveOut = 'hide-flexible-move-out',
	MembershipFee = 'membership-fee',
	PriceCalendar = 'price-calendar',
}

export const MEDIA_QUERY = {
	smDown: '(max-width: 767px)',
	mdDown: '(max-width: 1023px)',
	smUp: '(min-width: 768px)',
	largeTabletUp: '(min-width: 968px)',
	mdUp: '(min-width: 1024px)',
} as const

export const COOKIE_KEYS = {
	smartform: '__jh_s',
	smartformStep: '__jh_st',
	referral: '__jh_ref',
	banner: '__jh_pb',
	landingPageUrl: '__jh_landing_url',
	token: 'token',
	anonymousIdInternal: 'anonymous_id',
	authenticated: 'jn_authenticated',
}

export const STORAGE_KEYS = {
	currentCity: 'c_city',
	lastVisitedUrl: 'l_v_url',
}

export const Time = {
	SECOND: 1,
	SECOND_MS: 1e3,
	MINUTE: 60,
	MINUTE_MS: 60e3,
	HOUR: 3600,
	HOUR_MS: 3600e3,
	DAY: 86400,
	DAY_MS: 86400e3,
	WEEK: 604800,
	WEEK_MS: 604800e3,
} as const

export const ROOM_PHOTO_PLACEHOLDER = '/_next/image?url=/images/photo-placeholder.jpg'

export const CITY_SLUGS = {
	newYork: 'new-york-city-ny',
	boston: 'boston-ma',
	washington: 'washington-dc',
	chicago: 'chicago',
	austin: 'austin',
}

export const MIN_NEW_YORK_ROOM_SIZE = 80

export const DISABLED_CITIES_STATUS_CODE = 410

export const REFERRAL_REWARD_AMOUNT = 150

export const GoogleAuthConfig = {
	QUERY_PARAM: '?google-auth=true',
	LOGIN_CALLBACK_PATH: '/users/login/callback',
	REDIRECT_URI: '/s/users/server-side/google/login/',
}

export enum StatusCodes {
	CONTINUE = 100,
	OK = 200,
	BAD_REQUEST = 400,
	UNAUTHORIZED = 401,
	FORBIDDEN = 403,
	NOT_FOUND = 404,
	IM_A_TEAPOT = 418,
	TOO_MANY_REQUESTS = 429,
	INTERNAL_SERVER_ERROR = 500,
	NOT_IMPLEMENTED = 501,
	BAD_GATEWAY = 502,
	SERVICE_UNAVAILABLE = 503,
}

export const TRUSTPILOT_TEMPLATE_ID = {
	microCombo: '5419b6ffb0d04a076446a9af',
	mini: '53aa8807dec7e10d38f59f32',
	starter: '5613c9cde69ddc09340c6beb',
	microStar: '5419b732fbfb950b10de65e5',
	microTrustScore: '5419b637fa0340045cd0c936',
	horizontal: '5406e65db0d04a09e042d5fc',
}

export const TRUSTPILOT_BUSINESSUNIT_ID = '5c9b68c50e3f1d00015dbc6b'

export enum CatalogEngine {
	default = 'default',
	alternative = 'alternative',
}
